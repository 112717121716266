import { Component, Input, OnInit } from '@angular/core';

import { provideTranslocoScope } from '@jsverse/transloco';

import { BaseComponent, BaseModule } from 'app/client/modules/_base/base';

import { IPayment } from 'lc-interfaces';

@Component({
  selector: 'lc-payment-payment-block',
  standalone: true,
  imports: [BaseModule],
  providers: [
    provideTranslocoScope({
      scope: 'payment',
      alias: 'payment',
    }),
  ],
  templateUrl: './payment.block.html',
})
export class PaymentPaymentBlock extends BaseComponent implements OnInit {
  @Input({
    required: false,
  })
  payment!: IPayment;

  @Input({ required: false })
  payment_id!: string;

  public ngOnInit() {
    if (!this.payment && this.payment_id) {
      this.paymentService.findOneById(this.payment_id).subscribe(payment => (this.payment = payment));
    }
  }
}
